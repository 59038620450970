import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Man</h4>
              
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Man</h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">Libra Man Traits:</p> here are some main traits of libra men Sensitive but manly, emotionally balanced, objective and tactful, gentle and intelligent, charming and friendly, strong commitment, struggle with confrontation.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Compatibility Signs for Libra Men: </p>Gemini, Libra, Sagittarius, and Aquarius
 <br/><br/>
 
Libra is an excellent communicator. It is not the sort of person who is afraid to speak their mind, who is always willing to listen to others, able to understand what they are saying. It is an excellent communicator, but also a very good listener. It is also a good listener and a good leader.
 <br/><br/>
Here you see how, when someone is nervous, or perhaps feeling anxious, you can use the tone of your voice to gain their trust. Many times, you may have to be diplomatic in your approach. <br/><br/>
You can be diplomatic, tactful, and charismatic according to the situation. You to a cardinal sign so, you may often be motivated to start a conversation in social settings.<br/><br/>
You can also look and act differently depending on the situation, and that’s a great advantage. You are to use your professional authority and authority to achieve your goals.<br/><br/>
Libra men are known for their romantic feelings and are full of love and affection.  Libra men are also known for their great sexual appetite and for being emotionally expressive.  They are highly expressive and usually speak in many languages.  Libra men are highly visual, and will often describe their love in all sorts of ways.<br/><br/>
Libra men are ruled by Venus, the planet of love, pleasure, and relationships. Libra men are filled to the brim with romantic thoughts. They are more passionate and love to share romantic thoughts with others.<br/><br/>
You have a great taste in fashion and art, and all of your artists are wonderful. Your wardrobe is a reflection of your style. You are very active and have a strong work ethic. You have prevailed in many situations.
The occasions when you don't follow your "gut" instinct but are more or less right are rare.
 <br/><br/>
Are you seeking to understand your innate qualities and core strengths better?<br/>
<br></br>
Talk to our astrologer.<br/>
<br></br>
<p className="playfair text-black text-xl md:text-3xl">Let’s take a look at Libra men's traits</p><br/>
 <p className="playfair text-black text-xl md:text-3xl">Sensitive but manly</p><br/>
As you know that Libra men are ruled by venus  and They are a gentle, affectionate man who has warm, caring hearts. He was a kind gentleman who had a warm, loving heart.<br/><br/>
 
You are a good, kind, sensitive, passionate, intelligent, and confident man. You are a good man who is quick to anger, but when you are angry, you are also gentle and kind.<br/><br/>
You are both equally generous, kind, intelligent, reasonable, and unemotional, but you’re also stubborn and philosophically clear.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Emotionally balanced</p><br/>
You possess a balanced temperament. You possess a balanced temperament. You are an extrovert, an optimistic, and prefer to stay cool, calm, and collected.<br/><br/>
You tend to be very cheerful and enthusiastic, and you are the one who gets excited by most situations.<br/><br/>
You are a person who is very easy to get along with. You like to be around friends and people who like you, and you are very close to your family.<br/><br/>
You even get over things that upset you quite easily and quickly.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Objective and tactful</p><br/>
You say the right things to preserve your relationships. At the same time, you patiently listen to other people as well to understand their different different viewpoints. <br/><br/>
You’re charmingly and tactfully respond to what's been said. You know how to deal with people, and you also maintain that you always make other people comfortable in your presence.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Gentle and intelligent</p><br/>
The most valuable trait of a libra person is their personality. It is this personality that makes us feel at ease and confident in the world around us.  This personality also helps us to create a friendship with someone.<br/><br/>
Your personality has a big impact on how you interact with others. Therefore, it is important that you manage your interactions well. You’re very friendly in nature, friendship is very important part of your life, you are bery good in your creative ideas while conversing with people around you. You are very good at giving logical solutions to problems.
 
 <br/><br/>
Charming and friendly
You are creative, enthusiastic, classy, charming, open-minded, and lively. You possess a contagious zest for life. You possess a good memory and a good sense of timing. You are an excellent listener, and are able to put yourself in another person’s shoes. You are an optimistic person who likes to get things done and has a great work ethic.<br/><br/>
You have a good sense of humor and are an optimist. You are good at making quick decisions.<br/><br/>
You’re very popular among your friends and colleagues. You’re a dreamer, you don’t like to sit still. You like to take action and do things that make your life more adventurous and interesting.<br/><br/>
You are highly sociable and probably have an extensive network of friends and a hectic social life.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Strong commitment</p><br/>
You’re not that kind of a person who prefer casual hookups. You only come into a relationship when you find yourself ready first and then find potential in your partner. 
 <br/><br/>
You are the person believe in soulmate, you are incredibly honest and loyal in your relationships, you never cheat on your partners. When you said that youre with someone or you commit that relationship, you mean it. You care about your relation and don’t give up on them. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Struggle with confrontation</p><br/>
The hardest thing for you in the world is confrontation. when you know that you're right, you avaoid confronting. You always try to maintain peace and harmony whenever you have a heated conversation or argument. you never exaggerate any tough conversations.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How is Libra man in love and relationships?</p><br/>
Venus rules Libra men, and hence they possess a secret love ally. As a libra in love, you are all about romance and the beauty of love. You are devilishly adorable, very responsive, and socially confident. You very well know how to console your lady love. You want to be together with your lover all the time and find a very natural partnership. You want complete surety in a relationship. So you take time to learn about love relationships and often have an instinct of making women feel appreciated. When you find your ideal soulmate, your abundance of love overwhelms even the most romantic of hearts. You desire an interdependent and equally proportional relationship. Find your perfect partner to maintain equally balanced relationships from the free horoscope report.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Libra Men</p><br/>
 <p className="playfair text-black text-xl md:text-3xl">When you are with a Libra man, you should</p><br></br>- Be patient. - Be humble. - Be polite. - Be charming. -
 <br/><br/>
When you seek to attract a man’s attention and win his affection, you can do so through genuine interest in his interests and activities. It may be achieved by using hobbies that interest him and actions that he enjoys deeply.
<br/><br/> 
<p className="playfair text-black text-xl md:text-3xl"></p>If you want to attract Libra man’s attention, pay attention to the following tips.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Compliment and show them some appreciation:</p> Libra men have a favorable opinion of themselves and are very confident.A compliment can capture the attention of a Libra man. The female of the given sign should be sincere. It is important to convey a genuine compliment – there is nothing more important than the expression of appreciation.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Show your balanced and pleasant personality:</p>Show your balanced and pleasant personality: Libra is represented by the picture of scales for a good reason. Libra men are attracted to balanced people and situations. Show your friendly and balanced personality and present them with an overall balanced aura.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Social bees:</p> Libra men are highly social and like to be around people. They like to talk to people, are very outgoing, and like to be around people. They want to laugh and have fun and are very entertaining. 
Instead of sitting in the corner, you are now the center of attraction.
 

<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
